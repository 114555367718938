import React, { useState, useEffect } from "react";
import '../../styles/CommonBlocks/Reviews.scss';
import axios from "axios"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getReviews } from "../../asyncActions/reviewsAsync"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

function Reviews(props) {
	let dispatch = useDispatch()

    useEffect(() => {
        dispatch(getReviews())
    }, [])

    let reviews = useSelector((store) => store.reviewsReducer.reviews)
	let reviewsLength = reviews.length ? reviews.length  : 1

	return (
		<section className="reviews">
			
			<div className="container container--main-page">
			<h2 class="heading-service reviews__heading">Отзывы</h2>
			<Swiper
                    className="reviews__slider"
                    slidesPerView="auto"
                    loop={true}
                    loopedSlides= {reviewsLength}
                    navigation={{
                        nextEl: ".reviews__arrow-next",
                        prevEl: ".reviews__arrow-prev",
                    }}>
                    {reviews.map((review) => (
                        <SwiperSlide className="reviews__slide">
                                <div className="reviews__slide-title">
								{review.title}  
                                </div>
								<div className="reviews__slide-content">
									<p className="">{review.content}</p>
									<p className=""></p>
								</div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="reviews__navigation">
                    <div className="long-slider-arrow long-slider-arrow-prev reviews__arrow-prev">Предыдущий отзыв</div>
                    <div className="long-slider-arrow long-slider-arrow-next reviews__arrow-next">Следующий отзыв</div>
                </div>
			</div>
		</section>
	)
}

export default Reviews;