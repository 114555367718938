import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import mainPageReducer from "./mainPageReducer";
import projectsReducer from "./projectsReducer";
import serviceReducer from "./serviceReducer";
import reviewsReducer from "./reviewsReducer";
import quizReducer from "./quizReducer";

import thunk from "redux-thunk";



const rootReducer = combineReducers({
    mainPageReducer, 
    projectsReducer,
    serviceReducer,
    reviewsReducer,
    quizReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

