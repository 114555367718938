export const ModalOrderImg = ({classes}) => {
    return (
        <svg className={classes} width="158" height="79" viewBox="0 0 158 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 61C1 60.0749 1.407 59.0894 2.349 58.0405C3.29636 56.9857 4.74021 55.9202 6.68247 54.874C10.5657 52.7821 16.2507 50.8671 23.3607 49.2471C37.5647 46.0107 57.2366 44 79 44C100.763 44 120.435 46.0107 134.639 49.2471C141.749 50.8671 147.434 52.7821 151.318 54.874C153.26 55.9203 154.704 56.9857 155.651 58.0406C156.593 59.0894 157 60.0749 157 61C157 61.9251 156.593 62.9106 155.651 63.9594C154.704 65.0143 153.26 66.0797 151.318 67.126C147.434 69.2179 141.749 71.1329 134.639 72.7529C120.435 75.9892 100.763 78 79 78C57.2366 78 37.5647 75.9892 23.3607 72.7529C16.2507 71.1329 10.5657 69.2179 6.68247 67.126C4.7402 66.0797 3.29636 65.0143 2.349 63.9594C1.407 62.9105 1 61.925 1 61Z" stroke="#A99DFF" stroke-opacity="0.5" stroke-width="2"/>
        <path d="M1 46.789C1 45.864 1.407 44.8785 2.349 43.8296C3.29636 42.7748 4.74021 41.7093 6.68247 40.663C10.5657 38.5712 16.2507 36.6561 23.3607 35.0361C37.5647 31.7998 57.2366 29.7891 79 29.7891C100.763 29.7891 120.435 31.7998 134.639 35.0361C141.749 36.6562 147.434 38.5712 151.318 40.663C153.26 41.7093 154.704 42.7748 155.651 43.8296C156.593 44.8785 157 45.864 157 46.7891C157 47.7141 156.593 48.6996 155.651 49.7485C154.704 50.8034 153.26 51.8688 151.318 52.9151C147.434 55.0069 141.749 56.922 134.639 58.542C120.435 61.7783 100.763 63.7891 79 63.7891C57.2366 63.7891 37.5647 61.7783 23.3607 58.542C16.2507 56.922 10.5657 55.0069 6.68247 52.9151C4.7402 51.8688 3.29636 50.8033 2.349 49.7485C1.407 48.6996 1 47.7141 1 46.789Z" stroke="#A99DFF" stroke-opacity="0.5" stroke-width="2"/>
        <path d="M78.2928 74.3438C78.6833 74.7344 79.3165 74.7344 79.707 74.3438L86.0709 67.9799C86.4615 67.5893 86.4615 66.9562 86.0709 66.5657C85.6804 66.1751 85.0473 66.1751 84.6567 66.5657L78.9999 72.2225L73.343 66.5657C72.9525 66.1751 72.3193 66.1751 71.9288 66.5657C71.5383 66.9562 71.5383 67.5893 71.9288 67.9799L78.2928 74.3438ZM77.9999 9.63672L77.9999 73.6367L79.9999 73.6367L79.9999 9.63672L77.9999 9.63672Z" fill="#242426"/>
        <path d="M1 32.5703C1 31.6452 1.407 30.6597 2.349 29.6109C3.29636 28.556 4.74021 27.4906 6.68247 26.4443C10.5657 24.3524 16.2507 22.4374 23.3607 20.8174C37.5647 17.5811 57.2366 15.5703 79 15.5703C100.763 15.5703 120.435 17.5811 134.639 20.8174C141.749 22.4374 147.434 24.3524 151.318 26.4443C153.26 27.4906 154.704 28.556 155.651 29.6109C156.593 30.6598 157 31.6453 157 32.5703C157 33.4954 156.593 34.4809 155.651 35.5298C154.704 36.5846 153.26 37.65 151.318 38.6963C147.434 40.7882 141.749 42.7032 134.639 44.3232C120.435 47.5596 100.763 49.5703 79 49.5703C57.2366 49.5703 37.5647 47.5596 23.3607 44.3232C16.2507 42.7032 10.5657 40.7882 6.68247 38.6963C4.7402 37.65 3.29636 36.5846 2.349 35.5297C1.407 34.4809 1 33.4954 1 32.5703Z" stroke="#A99DFF" stroke-opacity="0.5" stroke-width="2"/>
        <path d="M1 18.3594C1 17.4343 1.407 16.4488 2.349 15.3999C3.29636 14.3451 4.74021 13.2796 6.68247 12.2333C10.5657 10.1415 16.2507 8.22645 23.3607 6.60645C37.5647 3.37011 57.2366 1.35937 79 1.35937C100.763 1.35937 120.435 3.37012 134.639 6.60646C141.749 8.22647 147.434 10.1415 151.318 12.2333C153.26 13.2796 154.704 14.3451 155.651 15.3999C156.593 16.4488 157 17.4343 157 18.3594C157 19.2844 156.593 20.2699 155.651 21.3188C154.704 22.3737 153.26 23.4391 151.318 24.4854C147.434 26.5772 141.749 28.4923 134.639 30.1123C120.435 33.3486 100.763 35.3594 79 35.3594C57.2366 35.3594 37.5647 33.3486 23.3607 30.1123C16.2507 28.4923 10.5657 26.5772 6.68247 24.4854C4.7402 23.4391 3.29636 22.3737 2.349 21.3188C1.407 20.2699 1 19.2844 1 18.3594Z" stroke="#A99DFF" stroke-opacity="0.5" stroke-width="2"/>
        </svg>

    );
};
