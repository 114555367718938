import React, { useRef } from "react";
import "../../../styles/pages/Home/Form.scss";
import "../../../styles/UI/hor-form.scss";
import CustomInput from '../../UI/CustomInput';
import { ServicesBtnArrow } from "../../Svgs/BTNArrow";
import InputMask from 'react-input-mask';
import circles from "../../../images/form/form_creat.png";
import circles_mob from "../../../images/form/form_creat-mob.png";

function Form({ formInfo }) {
    let btnSubmit = useRef()

    return (
        <section className="section main-form contacts">
            <div className="container container--main-page main-form__section--contacts">
                <form id='form' action="../mail2.php" method="POST" className="main-form__application fit-content application" onSubmit={(e) => { btnSubmit.current.setAttribute('disabled', 'disabled') }}>
                    <input style={{ display: `none` }} name="form" value={formInfo ? formInfo : `Форма в конце страницы`}/>

                    <h2 className="section-heading main-form__heading">Составим структуру сайта и&nbsp;план работ</h2>
                    <div className="application__input-container">
                        <CustomInput id="" name="user_name" type="text"  placeholder = "Имя" className='application__input'/>
                        <InputMask required mask="+7 (999) 999-99-99" id="" name="user_phone" type="text"  placeholder = "Телефон *" className='application__input' pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}" />
                    </div>
                    <div className="application__submit-container">
                        <button className="btn--64 btn" type="submit" ref={btnSubmit}>
                            Получить консультацию
                            <ServicesBtnArrow className="services__consultation-btn-arrow" />
                        </button>
                        <a href="/policy" className="application__policy">Нажимая на кнопку, вы даете согласие на обработку <span className="application__policy--link">персональных данных</span></a>
                    </div>
                    <img src={circles_mob} alt="" className="main-form__circles--mob" />
                </form>
                <img src={circles} alt="" className="main-form__circles" />
            </div>
        </section>
    )
}

export default Form;