import "../../../styles/pages/Thanks/Thanks.scss";
import "../../../styles/UI/hor-form.scss";
import {WD, WT, WM, TD, TT, TM } from "../../Svgs/Thanks";
import ballImg from "../../../images/first-section/3Dball2.png";

function Thanks() {
    return (
        <>
            <section className="first-section-bg section thanks">
                <div className="container container--main-page thanks__container">
                    <h1 className="thanks__heading">
                        Спасибо
                        <br />
                        за заявку
                        {/*<div className="thanks__btn-container thanks__btn-container--desk">
                            <a target="_blank" className="thanks__btn" href="https://t.me/+79153840955"><TD /></a>   
                            <a target="_blank" className="thanks__btn" href="https://wa.me/+79153840955"><WD /></a>   
                        </div>
                        <div className="thanks__btn-container thanks__btn-container--tab">
                            <a target="_blank" className="thanks__btn" href="https://t.me/+79153840955"><TT /></a> 
                            <a target="_blank" className="thanks__btn" href="https://wa.me/+79153840955"><WT /></a> 
                        </div>
                        <div className="thanks__btn-container thanks__btn-container--mob">
                            <a target="_blank" className="thanks__btn" href="https://t.me/+79153840955"><TM /></a> 
                            <a target="_blank" className="thanks__btn" href="https://wa.me/+79153840955"><WM /></a> 
                        </div>
                         */}
                    </h1>
                    <img src={ballImg} alt="" className="thanks-ball" />
                </div>
            </section>
        </>
    );
}

export default Thanks;
