import { setFaq, setSeo, setWhyWe } from "../store/mainPageReducer"
import { setHowWeWork } from "../store/mainPageReducer"
import axios from "axios"

export const getFaq = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/faq/").then(({ data }) => {
            dispatch(setFaq(data))
        })
    }
}

export const getHowWeWork = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/howwework/").then(({ data }) => {
            dispatch(setHowWeWork(data))
        })
    }
}


export const getWhyWe = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/whywe/").then(({ data }) => {
            dispatch(setWhyWe(data))
        })
    }
}

export const getSeo = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/mainpageseo/").then(({ data }) => {
            dispatch(setSeo(data))
        })
    }
}