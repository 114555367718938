import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/pages/Home/Faq.scss";

function Faq({questions}) {

    const [activeQuestion, setActiveQuestion] = useState(null);

    const onClickQuestion = (evt) => {
        let wrapper = evt.target
        while (!(wrapper?.classList?.contains('faq__item'))) {
            wrapper = wrapper.parentNode
        }
        wrapper.classList.toggle('faq__item--active')
    };

    return (
        <section className="faq section">
            <div className="container container--main-page faq__container">
                <h2 className="faq__heading section-heading">Часто задаваемые вопросы</h2>
                <div className="faq__items">
                    {questions.map((questionData, i) => (
                        <div
                            key={questionData.question}
                            className={`faq__item`}
                            data-index={i}
                            onClick={onClickQuestion} 
                        >
                            <div className="faq__item-inner">
                                <h6 className="faq__item-title">{questionData.question}</h6>
                                <div className="faq__item-answer">
                                    <p className="faq__text">{questionData.answer}</p>
                                </div>
                            </div>
                            <div className="faq__item-icon"></div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Faq;
