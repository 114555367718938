import React from 'react';



export const BTNArrow = (props) => {
    return (
        <svg className = "custom-button__icon" width="28" height="20" viewBox="0 0 28 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49304 18.5C15.4931 11.4806 13.2188 13.798 20.2189 6.7786M20.2189 6.7786L20.2189 18.5001M20.2189 6.7786L7.78125 6.7786" stroke="currentColor" strokeWidth="2" />
        </svg>

    );
};

export const ServicesBtnArrow = ({className}) => {
    return (
        <svg className = {className} width="49" height="16" viewBox="0 0 49 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.2071 8.70711C48.5976 8.31659 48.5976 7.68342 48.2071 7.2929L41.8431 0.928936C41.4526 0.538411 40.8195 0.538411 40.4289 0.928936C40.0384 1.31946 40.0384 1.95262 40.4289 2.34315L46.0858 8L40.4289 13.6569C40.0384 14.0474 40.0384 14.6805 40.4289 15.0711C40.8195 15.4616 41.4526 15.4616 41.8431 15.0711L48.2071 8.70711ZM0.5 9L47.5 9L47.5 7L0.5 7L0.5 9Z" fill="currentColor"/>
        </svg>

    );
};