import { useEffect } from "react";
import "../../styles/Modals/Modal.scss";

function Modal({ active, setActive, children, modalMod, image }) {
    useEffect(() => {
        if (active) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [active]);

    return (
        <>
            <div
                className={
                    `${active ? "modal modalActive" : "modal"}` +
                    " " +
                    `${modalMod ? "modal--" + modalMod : ""}`
                }
                onClick={() => setActive(false)}>
                <div
                    className={
                        `${active ? "modal__content modal__contentActive" : "modal__content"}` +
                        " " +
                        `${modalMod ? "modal__content--" + modalMod : ""}`
                    }
                    onClick={(e) => e.stopPropagation()}>
                    {children}
                    {!image ? 
                        <button className="modal__close" onClick={() => setActive(false)}></button>
                        :
                        <></>
                    }
                </div>
                {image ? 
                    <button className="modal__close" onClick={() => setActive(false)}></button>
                    :
                    <></>
                }
            </div>
        </>
    );
}

export default Modal;

