import React from "react";
import "../../../styles/pages/Home/FirstSection.scss";
import "../../../styles/UI/hor-form.scss";
import InputMask from "react-input-mask";
import CustomInput from "../../UI/CustomInput";
import ballImg from "../../../images/first-section/3Dball2.png";
import { Link } from "react-router-dom";
import { useRef } from "react";

function FirstSection(props) {
    let btnSubmit = useRef()

    return (
        <section className="first-section-bg section">
            <img src={ballImg} alt="" className="first-section-ball" />
            <div className="container container--main-page">
                <div className="first-section">
                    <h1 className="first-section__heading">
                        Создаем сайты, настраиваем рекламу, проводим исследования
                    </h1>
                    <form action="../mail2.php" method="post" className="first-section__form hor-form" onSubmit={(e) => { btnSubmit.current.setAttribute('disabled', 'disabled') }}>
                        <input style={{ display: `none` }} name="form" value='Форма на первом экране главной страницы'/>
                        <div className="hor-form__inner">
                            <div className="hor-form__inputs">
                                <CustomInput
                                    id=""
                                    type="text"
                                    className="hor-form__input hor-form__input-name"
                                    name="user_name"
                                    placeholder="Ваше имя"
                                />
                                <InputMask
                                    required
                                    mask="+7 (999) 999-99-99"
                                    id=""
                                    type="tel"
                                    className="hor-form__input hor-form__input-phone"
                                    name="user_phone"
                                    placeholder="Ваш телефон*"
                                    pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
                                />
                            </div>
                            <div className="hor-form__consent">
                                Нажимая «Отправить», я даю согласие на обработку
                                <Link to="/policy" target='_blank' className="hor-form__consent-link">
                                    персональных данных
                                </Link>
                            </div>
                        </div>
                        <button className="hor-form__submit btn btn--purple" ref={btnSubmit} >
                            Обсудить проект
                        </button>
                        <div className="hor-form__consent hor-form__consent--mobile">
                            Нажимая «Отправить», я даю согласие на обработку
                            <Link to="/policy" target='_blank' className="hor-form__consent-link">
                                персональных данных
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default FirstSection;
