import React, {useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "../../asyncActions/projectsAsync";
import { Link, useParams } from "react-router-dom";
import "../../styles/pages/Home/Works.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay, Navigation, Pagination]);

function Works({projects, headingText, headingClasses, cases}) {
    const { name } = useParams();

    let projectsLength = projects.length ? projects.length  : 1

    cases = cases?.split(',')


        

    return (
        cases ?
        <section className="works section">
            <div className="container container--main-page">
                <h2 className={headingClasses}>{headingText}</h2>

                <Swiper
                    className="works__slider"
                    slidesPerView="auto"
                    loop={true}
                    loopedSlides= {projectsLength}
                    spaceBetween={56}
                    navigation={{
                        nextEl: ".works__slider-arrow-next",
                        prevEl: ".works__slider-arrow-prev",
                    }}
                    pagination={{
                        el: ".works__slider-bullets-container",
                        bulletClass: "works__pagination-bullet",
                        bulletActiveClass: "works__pagination-bullet--active",
                        clickable: true,
                    }}
                    breakpoints={{
                        1201: {
                            spaceBetween: 56,
                        },
                        768: {
                            spaceBetween: 48,
                        },

                        0: {
                            spaceBetween: 12,
                        },
                    }}>
                    {projects?.map((site) => (
                        cases.find(item => (item == site.name)||(item == 'all'))?
                        <SwiperSlide className="works__slide">
                            <Link to={`/${site.link}`} className="works__slide-inner">
                                <div className="works__img-wrapper">
                                    <img src={site.miniImage} alt="" className="works__img" />
                                </div>
                                <div className="works__slide-title-wrapper">
                                    <div className="works__slide-title">{`${site.name} — ${site.title}`}</div>
                                    <div className="works__slide-arrow"></div>
                                </div>
                            </Link>
                        </SwiperSlide>
                        :
                        <></>
                        
                    ))}
                </Swiper>
                <div className="works__navigation">
                    <div className="works__slider-arrow works__slider-arrow-prev"></div>
                    <div className="works__slider-arrow works__slider-arrow-next"></div>
                    <div className="works__slider-bullets-container"></div>
                </div>
            </div>
        </section>
        :
        <></>
    );
}

export default Works;
