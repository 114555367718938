import React from 'react';
import '../styles/CommonBlocks/Header.css';

function Section(props) {
  if (props.children.props.name != null) {
    return (    
      <section className={`${props.children.props.className} section`}>
        <div className="section__name">
          {props.children.props.name}
        </div>
        <div className="section__content">
            {props.children}
        </div>
      </section>
    );
  } else {
    return (    
      <section className={`${props.children.props.className} section`}>
        <div className="section__content">
            {props.children}
        </div>
      </section>
    );
  }
}

export default Section;