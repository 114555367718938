import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/CommonBlocks/Header.css";
import "../../../styles/CommonBlocks/HeaderMobileMenu.scss";
import { LMGLogo } from "../../Svgs/LMGLogo";
import HeaderTabletMenu from "./HeaderTabletMenu";
import HeaderMobileMenu from "./HeaderMobileMenu";
import Modal from "../../Modals/Modal";
import ModalOrder from "../../Modals/Modals/ModalOrder";
import mobileGradient from "../../../images/header/mobile-menu-gradient.svg";

function Header({ btnMod, textColor }) {
    const [modalActive, setModalActive] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    let scrollPositionNeeded = 10;

    let mobileClient = false;

    if (window.matchMedia("(max-width: 575px)").matches) {
        mobileClient = true;
    }

    const [activeMobileMenu, setActiveMobileMenu] = useState(false);
    const [activeBurgerBtn, setActiveBurgerBtn] = useState(false);
    const [activeMobileNavigation, setActiveMobileNavigation] = useState(false);
    const [activeTabletMenu, setActiveTabletMenu] = useState(false);

    const toggleAdaptMenu = (speed) => {
        if (window.matchMedia("(max-width: 575px)").matches) {
            activeMobileNavigation
                ? setActiveMobileNavigation(false)
                : setActiveMobileNavigation(true);
            if (speed === "hasDelay" || activeBurgerBtn) {
                setTimeout(() => {
                    activeMobileMenu ? setActiveMobileMenu(false) : setActiveMobileMenu(true);
                }, 500);
            } else {
                activeMobileMenu ? setActiveMobileMenu(false) : setActiveMobileMenu(true);
            }
        } else {
            activeTabletMenu ? setActiveTabletMenu(false) : setActiveTabletMenu(true);
        }

        activeMobileMenu || activeTabletMenu
            ? (document.body.style.overflow = "auto")
            : (document.body.style.overflow = "hidden");
    };

    return (
        <div className={`header-wrapper`}>
            <div
                className={`header ${
                    scrollPosition > scrollPositionNeeded || activeMobileMenu ? "header--white" : ""
                } ${textColor ? `header--${textColor}-text` : ""}
                    ${activeMobileMenu ? "header--active" : ""}
                `}
            style = {{transition: activeMobileMenu ? '0s' : '.25s ease-in'}}>
                <header className="header-container container container--main-page container--header">
                    <Link to="/">
                        <LMGLogo classes="header__logo" />
                    </Link>
                    <div className="header__inner-wrapper">
                        <nav className="header__nav">
                            <ul className="header__nav-list">
                                <li className="header__nav-li">
                                    <Link to="/#works" className="header__nav-link">
                                        портфолио
                                    </Link>
                                </li>
                                <li className="header__nav-li">
                                    <Link to="/#services" className="header__nav-link">
                                        услуги
                                    </Link>
                                </li>
                                <li className="header__nav-li">
                                    <Link to="/#contacts" className="header__nav-link">
                                        контакты
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <a href="tel:+74956624976" className="header__phone">
                        +7 (495) 662-49-76
                        </a>
                        <div
                            onClick={() => setModalActive(true)}
                            className={`header__order-btn btn  ${
                                scrollPosition < scrollPositionNeeded
                                    ? `btn--${btnMod}`
                                    : "btn--blue"
                            }`}>
                            Оставить заявку
                        </div>
                        <button
                            className={`header__burger-btn ${
                                activeMobileNavigation ? "header__burger-btn--active" : ""
                            }
                        `}
                            onClick={() => {
                                activeBurgerBtn
                                    ? setActiveBurgerBtn(false)
                                    : setActiveBurgerBtn(true);
                                toggleAdaptMenu();
                            }}>
                            <span className="header__burger-btn-line"></span>
                        </button>
                    </div>
                </header>
            </div>
            <div
                className={`header__adaptive-menu-layout ${
                    activeMobileMenu || activeTabletMenu
                        ? "header__adaptive-menu-layout--active"
                        : ""
                } `}></div>

            <HeaderTabletMenu
                activeTabletMenu={activeTabletMenu}
                toggleAdaptMenu={toggleAdaptMenu}
            />

            <HeaderMobileMenu
                activeMobileMenu={activeMobileMenu}
                activeMobileNavigation={activeMobileNavigation}
                toggleAdaptMenu={toggleAdaptMenu}
            />
            <Modal active={modalActive} setActive={setModalActive} modalMod="mobile-bottom">
                <ModalOrder formInfo = 'Форма модальное окно, шапка, кнопка Оставить заявку' />
            </Modal>
        </div>
    );
}

export default Header;
