import axios from "axios";
import { useState, useRef } from "react";
import ReactInputMask from "react-input-mask";
import { ModalOrderImg } from "../../Svgs/Modals";
import CustomInput from "../../UI/CustomInput";
import "../../../styles/Modals/Modals/ModalOrder.scss";
import "../../../styles/Modals/Modals/ModalThanks.scss";
import { Link } from "react-router-dom";

function ModalOrder({formInfo}) {
    const [isFormSended, setIsFormSended] = useState(false);
    let btnSubmit = useRef();

    const onFormSubmit = (evt) => {
        evt.preventDefault();
        btnSubmit.current.setAttribute('disabled', 'disabled')

        const form = evt.target;

        axios({
            method: "post",
            url: "../mail.php",
            data: new FormData(form),
            headers: {
                "Content-type": "application/x-www-form-urlencoded",
            },
        })
            .then(function (response) {
                if (response.data === "ok") {
                    setIsFormSended(true);
                    setTimeout(() => {
                        setIsFormSended(false);
                    }, 300000);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            {isFormSended ? (
                <div className="modal-thanks">
                    <p className="modal-thanks__text">Спасибо! Ваша заявка принята </p>
                </div>
            ) : (
                    <form action="/" className="modal-order" onSubmit={onFormSubmit}>
                    <input style={{ display: `none` }} name="form" value={formInfo ? formInfo : `Форма модальное окно`}/>
                    <h3 className="modal-order__heading">Оставьте заявку</h3>
                    <ModalOrderImg classes="modal-order__img" />
                    <p className="modal-order__text">
                        Мы расскажем вам подробнее об услуге и сориентируем по примерной стоимости{" "}
                    </p>
                    <div className="modal-order__inputs">
                        <CustomInput
                            id=""
                            name="user_name"
                            type="text"
                            placeholder="Имя"
                            className="modal-order__input"
                        />
                        <ReactInputMask
                            required
                            mask="+7 (999) 999-99-99"
                            id=""
                            name="user_phone"
                            type="text"
                            placeholder="Телефон *"
                            className="modal-order__input"
                            pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
                        />
                    </div>
                    <button className="btn--64 btn modal-order__btn btn--blue" type="submit" ref={btnSubmit}>
                        Получить консультацию
                    </button>
                    <Link to="/policy" target='_blank' className="modal-order__policy">
                        Нажимая на кнопку, вы даете согласие на обработку{" "}
                        <span className="modal-order__policy-link">персональных данных</span>
                    </Link>
                </form>
            )}
        </>
    );
}

export default ModalOrder;
