import React from 'react';
import '../../styles/LMGLogo.scss';



export const LMGMinLogo = (props) => {
    return (
        <svg className={props.className} width="49" height="43" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5616 0.0078125C18.5041 0.0078125 17.6156 2.25664 17.6156 2.25664C17.6156 2.25664 16.7272 4.3219 15.8855 8.03935C14.6697 13.1795 13.4072 20.4768 13.3604 22.3584C11.9576 19.054 9.05846 5.92821 8.54409 3.17454C8.12324 0.971598 6.3931 0.971598 6.3931 0.971598C3.11986 1.43054 1.0624 6.70841 0.968874 17.4018C0.875353 27.7281 3.63423 39.4771 4.47592 41.4965C5.31761 43.5617 7.04775 42.5979 7.04775 42.5979C8.21676 41.8636 8.35705 40.9457 8.12324 39.3853C7.88944 37.779 7.18803 34.1074 6.53338 29.4721C5.83197 24.5614 5.55141 17.6313 6.1593 14.6023C7.88944 23.4599 12.659 33.0519 13.7813 35.3007C14.5294 36.8152 15.8855 37.3201 17.1948 36.0809C18.3638 34.9335 17.8027 32.8224 17.943 29.1049C18.0832 24.3319 18.5509 17.9067 19.4393 15.0612C20.468 23.0469 23.1802 30.1146 23.6945 31.4915C24.3024 33.006 25.4247 33.3272 26.3131 33.0519C27.6692 32.6388 27.7627 31.7209 27.903 30.39C28.0433 29.059 29.0252 24.1942 29.0252 24.1942C29.9137 19.6966 30.9424 16.6675 32.1114 14.5564C32.2985 20.0637 33.748 28.0035 34.2157 29.7475C34.6833 31.5374 35.1041 32.4093 36.2731 32.4093C37.7695 32.4552 38.1903 30.9866 38.1903 30.9866C38.1903 30.9866 40.1542 25.2039 41.4168 22.129C42.9131 18.4574 45.3447 15.1071 47.2151 13.0419C48.7582 11.3438 49.3193 9.23261 47.0281 9.09493C45.485 9.00314 43.6145 10.5636 41.5103 13.3172C39.4061 16.0709 37.8162 19.1917 37.1148 21.1652C36.5537 18.0444 36.1796 8.26883 36.1796 8.26883C36.1796 8.26883 35.8523 5.148 35.5717 4.55137C34.8703 3.12864 32.6726 2.94506 31.2697 4.78084C29.8669 6.66252 28.1368 11.1143 28.1368 11.1143C27.2951 12.9501 25.7052 18.3197 25.3779 19.8342C25.2376 19.4671 24.4427 15.9791 23.8816 12.537C23.2269 8.36062 22.9464 3.95474 22.8996 2.71559C22.8528 0.420863 21.1227 0.053707 21.1227 0.053707C20.8889 0.00781246 20.7486 0.0078125 20.5616 0.0078125Z" fill="#242426"/>
        </svg>
    );
};