import Header from "../../commonBlocks/Header/Header";
import Thanks from "./Thanks";
import Footer from "../../commonBlocks/Footer";
import { Helmet } from "react-helmet";

function ThanksPage() {
    return (
        <>
            <Helmet>
                <title>Лидер медиа - Спасибо за заказ</title>
            </Helmet>
            <div className="wrapper">
                <Header btnMod="purple" textColor="white" />
                <Thanks />
            </div>
        </>
    );
}

export default ThanksPage;
