import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getHowWeWork } from "../../../asyncActions/mainPageAsync";
import "../../../styles/pages/Home/Services.scss";
import Modal from "../../Modals/Modal";
import { ServicesBtnArrow } from "../../Svgs/BTNArrow";
import { ModalOrderImg } from "../../Svgs/Modals";
import { ServicesItemArrow } from "../../Svgs/Svgs";
import CustomInput from "../../UI/CustomInput";
import ModalOrder from "../../Modals/Modals/ModalOrder";

function Serviсes({servicesArr}) {

    // let categoriesArr = useSelector((store) => store.serviceReducer.servicesCategories);

    // const getFilteredServicesArr = (servicesArr, categoryId) => {
    //     const filteredServicesArr = [...servicesArr].filter(
    //         (service) => service.сategory === categoryId,
    //     );
    //     return filteredServicesArr;
    // };

    // const [activeCategory, setActiveCategory] = useState({
    //     id: 3,
    //     name: "Аналитика",
    // });

    // let filteredServicesArr = getFilteredServicesArr(servicesArr, activeCategory?.id);

    // const onClickCategoryBtn = (category) => {
    //     setActiveCategory(category);
    // };
    

    let filteredServicesArr = [...servicesArr].filter(
        (service) =>
            service.name === "Корпортивный сайт" ||
            service.name === "Лендинг" ||
            service.name === "Интернет магазин" ||
            service.name === "Информационный сайт",
    );

    const [modalActive, setModalActive] = useState(false);
    const [formInfo, setFormInfo] = useState('');
    

    return (
        <>
            <section className="services section">
                <div className="services__header container container--main-page container--main-page-services">
                    <h2 className="services__heading section-heading">Услуги</h2>
                    {/* <div className="services__categories">
                    {categoriesArr.map((category) => (
                        <button
                            key={category.name}
                            type="button"
                            className={`services__category-btn btn btn--tab ${
                                category?.id === activeCategory?.id ? "btn--tab-active" : ""
                            }`}
                            onClick={(evt) => onClickCategoryBtn(category)}
                        >
                            {category.name}
                        </button>
                    ))}
                </div> */}
                </div>
                <div className="services__items">
                    {filteredServicesArr.map((service) => (
                        <div className="services__item" onClick={() => { setModalActive(true); setFormInfo(`услугу ${service.name}`) }}>
                            <div className="services__item-gradient"></div>
                            <div className="services__item-inner container container--main-page">
                                <div className="services__left-wrapper">
                                    <span className="services__item-title">{service.name}</span>
                                    {/*<span className="services__item-price">
                                        от {service.price} ₽
                                    </span>*/}
                                </div>
                                <ServicesItemArrow className="services__item-arrow" />
                                <p className="services__description">{service.shortDescription}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="container container--main-page">
                    <div onClick={() => { setModalActive(true); setFormInfo(`кнопку Получить бесплатную консультацию`) }} className="btn--64 btn btn--has-arrow services__btn">
                        Получить бесплатную консультацию
                        <ServicesBtnArrow className="services__consultation-btn-arrow" />
                    </div>
                </div>
            </section>

            <Modal active={modalActive} setActive={setModalActive} modalMod = 'mobile-bottom'>
                <ModalOrder formInfo={`Форма модальное окно, блок услуг, нажатие на ${formInfo}`} />
            </Modal>
        </>
    );
}

export default Serviсes;
