import React from 'react';
import '../../styles/UI/CustomInput.scss';


function CustomInput({children, className, ...props}) {
  return (
   <input {...props} className = {className}/>
       
  )
}

export default CustomInput;