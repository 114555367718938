import Header from "../../commonBlocks/Header/Header";
import Section from "../../Section";
import Pol from "./Pol";
import Footer from "../../commonBlocks/Footer";
import { Helmet } from "react-helmet";

function PolicyPage() {
    return (
        <>
            <Helmet>
                <title>Лидер медиа - Политика конфиденциальности</title>
            </Helmet>
            <div className="wrapper">
                <Header />
                <Pol name="Политика конфиденциальности" className="section--flex" />
                <Footer />
            </div>
        </>
    );
}

export default PolicyPage;
