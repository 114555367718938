import React, { useState, useEffect } from "react";
import BreadCrumbs from '../../../UI/BreadCrumbs';
import { useDispatch, useSelector } from "react-redux"
import { getServices } from "../../../../asyncActions/serviceAsync"
import { ServicesBtnArrow } from "../../../Svgs/BTNArrow";
import '../../../../styles/pages/Service/FirstBlock.scss';
import { Helmet } from "react-helmet";

function FirstBlock(props) {

    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(getServices())
    }, [])

    let services = useSelector((store) => store.serviceReducer.services)

    return (
        services[0]?.name ?
        <section className="first-block">
            <BreadCrumbs pageName={services[0].link} classes="container container--main-page" />
            <div className="container container--main-page first-block__container">
                <div className="first-block__content">
                    <div className="first-block__main-info">
                        <h1 className="first-block__title">{services[0].name}</h1>
                        <div className="first-block__front-image-container first-block__front-image-container--mobile">
                            <img className="first-block__front-image" src={services[0].Frontimage}></img>
                        </div>
                        <h2 className="first-block__type">{services[0].about}</h2>
                        <a href='' className="btn--link btn first-block__btn">
                            Обсудить проект
                            <ServicesBtnArrow className="services__consultation-btn-arrow" />
                        </a>
                    </div>
                </div>
                <div className="first-block__front-image-container">
                    <img className="first-block__front-image" src={services[0].Frontimage}></img>
                </div>
            </div>
        </section>
        :
        <></>
    );
}

export default FirstBlock;
