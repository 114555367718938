import "../../../styles/pages/Home/WhyWe.scss";
import img1 from "../../../images/why-we/1.png";
import img2 from "../../../images/why-we/2.png";
import img3 from "../../../images/why-we/3.png";
import img4 from "../../../images/why-we/4.png";
import { useEffect } from "react";
import { getWhyWe } from "../../../asyncActions/mainPageAsync";
import { useDispatch, useSelector } from "react-redux";

function WhyWe({whyWeArr}) {

    return (
        <>
            <section className="why-we section">
                <div className="container container--main-page">
                    <h2 className="why-we__heading section-heading">Почему мы</h2>
                    <div className="why-we__items">
                        {whyWeArr?.map((item, index) => (
                            <div className="why-we__item">
                                <img src={item.img} alt="" className="why-we__item-img" />
                                <span className="why-we__item-number">{index + 1}</span>
                                <div className="why-we__text-container">
                                    <h5 className="why-we__item-title">
                                        <span className="why-we__item-number-mobile">{index + 1}</span>
                                        {item.name}
                                    </h5>
                                    <p className="why-we__item-description">{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default WhyWe;
