import React, { useEffect, useState } from "react"
import Header from "../../commonBlocks/Header/Header";
import Projects from "./Projects";
import Footer from "../../commonBlocks/Footer";
import {getProjects} from "../../../asyncActions/projectsAsync"
import { useDispatch, useSelector } from "react-redux"
import Preloader from '../../UI/Preloader';
import axios from "axios"


function ProjectsPage() {
    const [isLoading, setLoading] = useState(true)
    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProjects())
    }, [])
    
    let projects = useSelector((store) => store.projectsReducer.projects)
    if (projects.length > 0) {
        setTimeout(() => {
            setLoading(false)
        }, 300);  
    }

    return (
        
        <div className="wrapper">
            <Header btnMod = 'blue'/>
            <Projects projects={projects} name=""/>
            <Footer />
            {isLoading ? 
                <div class="preloader-wrapper">
                    <Preloader/>
                </div>
                :
                <></>
            }
        </div>
    )
}

export default ProjectsPage;