import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor() {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if (hash !== "") {
            setTimeout(() => {
                const blockClass = hash.replace("#", "");
                const block = document.querySelector(`.${blockClass}`);
                if (block) {
                    setTimeout(function () {
                        const blockPosition = block.offsetTop;
                        let offset = 120;

                        if (window.matchMedia("(max-width: 1500px)").matches) {
                            offset = 100;
                        }
                        if (window.matchMedia("(max-width: 360px)").matches) {
                            offset = 20;
                        }
                        if (window.matchMedia("(max-width: 320px)").matches) {
                            offset = 30;
                        }

                        window.scrollTo({
                            behavior: "smooth",
                            top: blockPosition - offset,
                        });
                    }, 5);
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    return null;
}

export default ScrollToAnchor;
