const defaultState  = {
    projects: []
}

export const SET_PROJECTS = 'SET_PROJECTS';

export default function projectsReducer (state = defaultState, action) {
    switch (action.type) {
        case SET_PROJECTS:
            return {...state, projects: action.payload}

        default:
            return state;
    }
}

export const setProjects = (payload) => ({type: SET_PROJECTS, payload})

