const defaultState = {
    conditions: [],
    services: [],
    servicesCategories: [],
};

export const SET_CONDITIONS = "SET_CONDITIONS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_SERVICES_CATEGORIES = "SET_SERVICES_CATEGORIES";

export default function serviceReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_SERVICES:
            return { ...state, services: action.payload };
        
        case SET_SERVICES_CATEGORIES:
            return { ...state, servicesCategories: action.payload };

        case SET_CONDITIONS:
            return { ...state, conditions: action.payload };

        default:
            return state;
    }
}

export const setServices = (payload) => ({ type: SET_SERVICES, payload });
export const setServicesCategories = (payload) => ({ type: SET_SERVICES_CATEGORIES, payload });
export const setConditions = (payload) => ({ type: SET_CONDITIONS, payload });
