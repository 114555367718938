
import axios from "axios"
import { setConditions, setServices, setServicesCategories } from "../store/serviceReducer"

export const getConditions = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/conditions/").then(({ data }) => {
            dispatch(setConditions(data))
        })
    }
}

export const getServices = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/service/").then(({ data }) => {
            dispatch(setServices(data))
        })
    }
}

export const getServicesCategories = () => {
    return function (dispatch) {
        axios.get("https://lm-test.store/api/service-categories/").then(({ data }) => {
            dispatch(setServicesCategories(data))
        })
    }
}