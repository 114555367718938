const defaultState  = {
    quiz: []
}

export const SET_QUIZ = 'SET_QUIZ';

export default function quizReducer (state = defaultState, action) {
    switch (action.type) {
        case SET_QUIZ:
            return {...state, quiz: action.payload}

        default:
            return state;
    }
}

export const setQuiz = (payload) => ({type: SET_QUIZ, payload})