import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../../Modals/Modal";
import ModalOrder from "../../Modals/Modals/ModalOrder";

function HeaderTabletMenu({ activeTabletMenu, toggleAdaptMenu }) {
    const [modalActive, setModalActive] = useState(false);
    return (
        <>
            <div
                className={`header__tablet-menu ${
                    activeTabletMenu ? "header__tablet-menu_active" : ""
                }`}
            >
                <div className="header__tablet-close" onClick={() => toggleAdaptMenu()}></div>
                <nav className="header__nav-tablet">
                    <ul className="header__nav-tablet-list">
                        <li className="header__nav-adapt-li">
                            <Link
                                to="/#works"
                                className="header__nav-adapt-link "
                                onClick={() => toggleAdaptMenu()}
                            >
                                портфолио
                                <span className="header__nav-adapt-arrow"></span>
                            </Link>
                        </li>
                        <li className="header__nav-adapt-li">
                            <Link
                                to="/#services"
                                className="header__nav-adapt-link"
                                onClick={() => toggleAdaptMenu()}
                            >
                                услуги
                                <span className="header__nav-adapt-arrow"></span>
                            </Link>
                        </li>
                        <li className="header__nav-adapt-li">
                            <Link
                                to="/#contacts"
                                className="header__nav-adapt-link"
                                onClick={() => toggleAdaptMenu()}
                            >
                                контакты
                                <span className="header__nav-adapt-arrow"></span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <a href="tel:+74956624976" className="header__phone-adapt">
                +7 (495) 662-49-76
                </a>
                <div
                    onClick={() => setModalActive(true)}
                    className={`header__order-btn header__order-btn-adapt  btn btn--blue`}
                >
                    Оставить заявку
                </div>
            </div>
            <Modal active={modalActive} setActive={setModalActive} modalMod = 'mobile-bottom'>
                <ModalOrder formInfo = 'Форма модальное окно, шапка, кнопка Оставить заявку' />
            </Modal>
        </>
    );
}

export default HeaderTabletMenu;
