import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import InputMask from 'react-input-mask';
import CustomInput from '../../UI/CustomInput';
import BreadCrumbs from '../../UI/BreadCrumbs';
import Preloader from '../../UI/Preloader';
//import OpeningImage from '../../UI/ImagePreview';
import { ServicesBtnArrow } from "../../Svgs/BTNArrow";
import Smile1 from '../../../images/smiles/((.png';
import Smile2 from '../../../images/smiles/(.png';
import Smile3 from '../../../images/smiles/).png';
import '../../../styles/pages/Project/projectPage.scss';
import { Helmet } from "react-helmet";
import Modal from "../../Modals/Modal";
import ModalEstimation from "../../Modals/Modals/ModalEstimation";


function Projects({projects}) {
    function onSubmit() {
        axios.post('https://lm-test.ru/thank-you.html', {
            user_name: '',
            user_phone: ''
        })
    }
    
    const [modalActive, setModalActive] = useState(false);
    const [imgModalActive, setImgModalActive] = useState(false);
    const [ModalImage, setModalImage] = useState(false);
    const [estimation, setEstimation] = useState(false);

    let btnSubmit = useRef()
    
    function openImage(src) {
        setImgModalActive(true)
        setModalImage(src)
    }

    function openEstimationModal(val) {
        setModalActive(true)
        setEstimation(val)
    }

    const {name} = useParams()

    
    
    return (
        <>
        {projects.map((project, index) => (
            project.link == name ?
            <>
                <Helmet>
                    <title>Лидер медиа - Кейсы - {project.name}</title>    
                    <meta name="description" content={project?.metaDescription} />
                </Helmet>   
                    
                <div className="project-page">
                    <BreadCrumbs pageName={project.name} classes="container container--project-page" />
                    <section style={{order: "1"}} className="project-page__item project-first-block">
                        <div className="project-page__first-section container container--project-page">
                            <div className="project-first-block__content">
                                <div className="project-first-block__main-info">
                                    <h1 className="project-page__title">{project.name}</h1>
                                    <h2 className="project-page__type">{project.title}</h2>
                                    <a target='_blank' href={`https://${project.siteLink}`} className="btn--link btn">
                                        {project.siteLink}
                                        <ServicesBtnArrow className="services__consultation-btn-arrow" />
                                    </a>
                                </div>
                                <div className="project-page__content-box project-first-block__bottom">
                                    <h2 className="project-page__heading">{project.subTitle1}</h2>
                                    <p className="project-page__text">{project.content1}</p>
                                </div>
                            </div>
                            {project.video ?
                            <iframe className="project-page__iframe project-page__front-image" src={`https://www.youtube.com/embed/${project.video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            :
                            <img onClick = {() => openImage(project.frontImage)} className="project-page__front-image" src={project.frontImage}></img>
                            }   
                            <div className="project-page__content-box project-first-block__bottom project-first-block__bottom--mobile">
                                <h2 className="project-page__heading">{project.subTitle1}</h2>
                                <p className="project-page__text">{project.content1}</p>
                            </div>
                        </div>
                    </section>
                    {project.content2 ?
                    <section className="project-page__item project-page__item--custom-color" style={{background: project.background, color: project.color, order: "3"}}>
                        <div className="project-page__section container container--project-page">
                            <img onClick = {() => openImage(project.phoneImage2)} className="project-page__phone-image" src={project.phoneImage2}></img>
                            <img onClick = {() => openImage(project.deskimage2)} className="project-page__desk-image" src={project.deskimage2}></img>
                            <div className="project-page__content-box project-page__content--list">
                                <h2 className="project-page__heading">{project.subTitle2}</h2>
                                <ul className="project-page__text" dangerouslySetInnerHTML={{ __html: `<li>${project.content2?.replace(/;/g,";</li><li>")}</li>` }}></ul>
                            </div>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.content3 ?
                    <section style={{order: "5"}} className="project-page__item project-page__item--white">
                        <div className="project-page__section container container--project-page">
                            <div className="project-page__content-box project-page__content--list project-page__content-box--small">
                                <h2 className="project-page__heading">{project.subTitle3}</h2>
                                <ul className="project-page__text" dangerouslySetInnerHTML={{ __html: `<li>${project.content3?.replace(/;/g,";</li><li>")}</li>` }}></ul>
                            </div>
                            <img onClick = {() => openImage(project.deskimage3)} className="project-page__desk-image" src={project.deskimage3}></img>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.content4 ?
                    <section className="project-page__item project-page__item--custom-color" style={{background: project.background, color: project.color, order: "7"}}>
                        <div className="project-page__section container container--project-page">
                            <img onClick = {() => openImage(project.phoneImage4)} className="project-page__phone-image" src={project.phoneImage4}></img>
                            <img onClick = {() => openImage(project.deskimage4)} className="project-page__desk-image" src={project.deskimage4}></img>
                            <div className="project-page__content-box project-page__content--list">
                                <h2 className="project-page__heading">{project.subTitle4}</h2>
                                <ul className="project-page__text" dangerouslySetInnerHTML={{ __html: `<li>${project.content4?.replace(/;/g,";</li><li>")}</li>` }}></ul>
                            </div>
                        </div>
                    </section>
                    :
                    <></>
                    }   
                    {project.content5 ?
                    <section style={{order: "9"}} className="project-page__item project-page__item--white">
                        <div className="project-page__section container container--project-page">
                            <div className="project-page__content-box project-page__content--list project-page__content-box--small">
                                <h2 className="project-page__heading">{project.subTitle5}</h2>
                                <ul className="project-page__text" dangerouslySetInnerHTML={{ __html: `<li>${project.content5?.replace(/;/g,";</li><li>")}</li>` }}></ul>
                            </div>
                            <img onClick = {() => openImage(project.deskimage5)} className="project-page__desk-image" src={project.deskimage5}></img>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.content6 ?
                    <section className="project-page__item--development project-page--custom-color" style={{background: project.background, color: project.color, order: "11"}}>
                        <div className="project-page__section container container--project-page">
                            <img onClick = {() => openImage(project.deskimage6)} className="project-page__footer-desk-image" src={project.deskimage6}></img>
                            <img onClick = {() => openImage(project.phoneImage6)} className="project-page__footer-phone-image" src={project.phoneImage6}></img>
                            <div className="project-page__content-box">
                                <h2 className="project-page__heading" style={{color: `${project.color}`}}>{project.subTitle6}</h2>
                                <p className="project-page__text">{project.content6}</p>
                            </div>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.OptionalImage7 ?
                    <section style={{order: `${project.order7*2-2}`}} className="project-page__item project-page__item--white">
                        <div className="project-page__section container container--project-page">
                            <img onClick = {() => openImage(project.OptionalImage7)} className="project-page__independent-img" src={project.OptionalImage7}></img>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.OptionalImage8 ?
                    <section style={{order: `${project.order8*2-2}`}} className="project-page__item project-page__item--white">
                        <div className="project-page__section container container--project-page">
                            <img onClick = {() => openImage(project.OptionalImage8)} className="project-page__independent-img" src={project.OptionalImage8}></img>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.video10 ?
                    <section style={{order: `${project.order10*2-2}`}} className="project-page__item project-page__item--white">
                        <div className="project-page__section container container--project-page">
                            <iframe className="project-page__video" src={`https://www.youtube.com/embed/${project.video10}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </section>
                    :
                    <></>
                    }
                    {project.video11 ?
                    <section style={{order: `${project.order11*2-2}`}} className="project-page__item project-page__item--white">
                        <div className="project-page__section container container--project-page">
                            <iframe className="project-page__video" src={`https://www.youtube.com/embed/${project.video11}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </section>
                    :
                    <></>
                    }

                    <section className="project-page__item--development project-page--custom-color" style={{background: project.background, color: project.color, order: "16"}}>
                        <form className="project-page__survey survey">
                            <a target='_blank' href={`https://${project.siteLink}`} className="btn--link btn">
                                {project.siteLink}
                                <ServicesBtnArrow className="services__consultation-btn-arrow" />
                            </a>
                            <span className="project-page__question">Понравилась работа?</span>
                            <div className="survey__container">
                                <form onClick={() => openEstimationModal("плохо")} className="survey__label">
                                    <img className="survey__img" src={Smile1}/>
                                    <input className="survey__input" type="text" name="smile" id="smile1"/>
                                </form>
                                <form onClick={() => openEstimationModal("средне")} className="survey__label">
                                    <img className="survey__img" src={Smile2}/>
                                    <input className="survey__input" type="text" name="smile" id="smile2"/>
                                </form>
                                <form onClick={() => openEstimationModal("хорошо")} className="survey__label">
                                    <img className="survey__img" src={Smile3}/>
                                    <input className="survey__input" type="text" name="smile" id="smile3"/>
                                </form>
                            </div>
                        </form>
                    </section>
                    <section style={{order: "17"}} className="project-page__item project-page__item--white application-form">
                        <div className="project-page__section project-page__section--contacts container container--project-page">
                                <form id='form' action="../mail2.php" method="POST" className="project-page__application application" onSubmit={(e) => { btnSubmit.current.setAttribute('disabled', 'disabled') }}>
                                <input style={{ display: `none` }} name="form" value={`Форма в конце страницы кейса ${project.name}`}/>
                                <h2 className="application__heading">Составим структуру сайта и&nbsp;план работ</h2>
                                <h2 className="application__heading application__heading--mobile">Проконсультируем бесплатно</h2>
                                <div className="application__input-container">
                                    <CustomInput id="" name="user_name" type="text"  placeholder = "Имя" className='application__input'/>
                                    <InputMask required mask="+7 (999) 999-99-99" id="" name="user_phone" type="text"  placeholder = "Телефон *" className='application__input' pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}" />
                                </div>
                                <div className="application__submit-container">
                                    <button className="btn--64 btn" type="submit" ref ={btnSubmit}>
                                        Получить консультацию
                                        <ServicesBtnArrow className="services__consultation-btn-arrow" />
                                    </button>
                                    <a href="/policy" className="application__policy">Нажимая на кнопку, вы даете согласие на обработку <span className="application__policy--link">персональных данных</span></a>
                                </div>
                            </form>
                        </div>
                    </section>
                    <Modal active={modalActive} setActive={setModalActive}>
                        <ModalEstimation val={estimation} projectName = {project.name}/>
                    </Modal>
                    <Modal active={imgModalActive} setActive={setImgModalActive} image={ModalImage}>
                        <img className="large-image" src={ModalImage}></img>
                    </Modal>
                </div>
                
            </>
            : 
            <></>//заглушка
        ))}
    </>
    )
}

export default Projects;
