import React from 'react';
import '../../styles/UI/BreadCrumbs.scss';


function BreadCrumbs(props) {

  return (
      <div className={`bread-crumbs ${props.classes}`}>
          <a href='../' className='bread-crumbs__link'>Главная</a>  &nbsp;/&nbsp; <span>{props.pageName}</span>
      </div>
  )
}

export default BreadCrumbs;