const defaultState  = {
    reviews: []
}

export const SET_REVIWES = 'SET_REVIWES';

export default function reviewsReducer (state = defaultState, action) {
    switch (action.type) {
        case SET_REVIWES:
            return {...state, reviews: action.payload}

        default:
            return state;
    }
}

export const setReviews = (payload) => ({type: SET_REVIWES, payload})