const defaultState  = {
    faq: [],
    HowWeWork: [],
    whyWe: [],
    seo: {}
}

export const SET_HOW_WE_WORK = 'SET_HOW_WE_WORK';
export const SET_FAQ = 'SET_FAQ';
export const SET_WHYWE = 'SET_WHYWE';
export const SET_SEO = 'SET_SEO';


export default function mainPageReducer (state = defaultState, action) {
    switch (action.type) {
        case SET_HOW_WE_WORK:
            return { ...state, HowWeWork: action.payload }
        
        case SET_FAQ:
            return { ...state, faq: action.payload }
        
        case SET_WHYWE:
            return { ...state, whyWe: action.payload }
        
        case SET_SEO:
            return {...state, seo: action.payload[0]}

        default:
            return state;
    }
}

export const setHowWeWork = (payload) => ({type: SET_HOW_WE_WORK, payload})
export const setFaq = (payload) => ({ type: SET_FAQ, payload })
export const setWhyWe = (payload) => ({ type: SET_WHYWE, payload })
export const setSeo = (payload) => ({ type: SET_SEO, payload })


