import React from 'react';
import '../../styles/LMGLogo.scss';



export const LMGLogo = ({classes}) => {
    return (
        <svg className={classes} width="187" height="58" viewBox="0 0 187 58" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M28.4229 4.89941C26.199 4.89941 25.2386 7.36448 25.2386 7.36448C25.2386 7.36448 24.2783 9.62832 23.3685 13.7032C22.0543 19.3377 20.6896 27.3366 20.639 29.3992C19.1227 25.777 15.9889 11.3891 15.4329 8.37063C14.978 5.95587 13.1079 5.95587 13.1079 5.95587C9.56975 6.45895 7.34579 12.2443 7.2447 23.966C7.14361 35.2851 10.1257 48.1638 11.0355 50.3774C11.9454 52.6412 13.8155 51.5848 13.8155 51.5848C15.0791 50.7798 15.2308 49.7737 14.978 48.0632C14.7253 46.3025 13.9671 42.2779 13.2595 37.1968C12.5013 31.8139 12.1981 24.2175 12.8552 20.8972C14.7253 30.6065 19.8809 41.1208 21.0939 43.5859C21.9027 45.246 23.3684 45.7994 24.7837 44.4411C26.0473 43.1834 25.4408 40.8693 25.5924 36.7944C25.744 31.5624 26.2495 24.5193 27.2098 21.4003C28.3218 30.1538 31.2534 37.9011 31.8094 39.4103C32.4665 41.0705 33.6796 41.4226 34.6399 41.1208C36.1057 40.668 36.2068 39.6619 36.3584 38.203C36.5101 36.7441 37.5715 31.4115 37.5715 31.4115C38.5319 26.4813 39.6438 23.161 40.9075 20.8469C41.1096 26.8838 42.6765 35.587 43.182 37.4987C43.6874 39.4607 44.1423 40.4165 45.4059 40.4165C47.0234 40.4668 47.4783 38.857 47.4783 38.857C47.4783 38.857 49.6011 32.5182 50.9658 29.1476C52.5833 25.123 55.2116 21.4506 57.2334 19.1867C58.9014 17.3254 59.5079 15.0112 57.0312 14.8603C55.3632 14.7597 53.3414 16.4701 51.0669 19.4886C48.7924 22.507 47.0739 25.9279 46.3157 28.0912C45.7092 24.6703 45.3048 13.9548 45.3048 13.9548C45.3048 13.9548 44.951 10.5339 44.6478 9.87985C43.8896 8.32032 41.514 8.11909 39.9977 10.1314C38.4813 12.194 36.6112 17.0738 36.6112 17.0738C35.7013 19.0861 33.9828 24.9721 33.629 26.6322C33.4774 26.2298 32.6181 22.4064 32.0116 18.6334C31.304 14.0554 31.0007 9.22586 30.9502 7.86756C30.8996 5.35218 29.0295 4.94972 29.0295 4.94972C28.7767 4.89941 28.6251 4.89941 28.4229 4.89941Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M77.5927 20.1853V32.0852H75.3922V21.9374H72.0915V25.1497C72.0915 27.9969 71.7981 30.6251 70.0377 31.6471C69.5243 31.9392 68.7174 32.2312 67.9106 32.2312L67.6172 30.4791C68.1306 30.4061 68.6441 30.114 68.9375 29.822C69.7443 29.019 69.891 27.1208 69.891 25.2227V20.1123H77.5927V20.1853Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M82.1397 20.1853V24.4196C82.1397 26.3178 82.0664 28.0699 81.993 29.676C82.5798 28.3619 83.3867 26.9018 84.1935 25.5877L87.3475 20.2583H89.548V32.1582H87.4942V27.9239C87.4942 26.0257 87.4942 24.4926 87.6409 22.9595C87.0541 24.3466 86.2473 25.8067 85.5138 27.0478L82.5065 32.0852H80.0859V20.1853H82.1397Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M95.71 23.8356C95.71 25.4417 95.5633 26.9018 95.1232 28.2159C94.9032 29.0189 94.5364 29.749 94.243 30.333H98.7907V21.9374H95.71V23.8356ZM100.991 20.1853V30.406L102.165 30.4791L102.018 35.0784H100.331L100.184 32.1582H92.9227L92.776 35.0784H91.089L91.0156 30.4791L91.9692 30.406C92.4093 29.603 92.8494 28.7269 93.1428 27.7779C93.5095 26.5368 93.6562 25.0767 93.6562 23.3245V20.1853H100.991Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M110.6 26.7558H106.052V30.26H111.113V32.0852H103.852V20.1853H110.82V21.9374H106.052V25.0037H110.6V26.7558Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M115.219 25.8797C115.513 25.9527 115.806 25.9527 116.319 25.9527C117.933 25.9527 118.96 25.1497 118.96 23.7626C118.96 22.4485 118.006 21.7184 116.539 21.7184C115.953 21.7184 115.513 21.7914 115.293 21.7914V25.8797H115.219ZM113.019 20.3313C113.826 20.1853 114.852 20.1123 116.393 20.1123C118.006 20.1123 119.18 20.4773 119.914 21.0614C120.647 21.6454 121.087 22.5945 121.087 23.6896C121.087 24.8577 120.72 25.7337 120.06 26.3908C119.18 27.2668 117.786 27.6319 116.246 27.6319C115.806 27.6319 115.439 27.6318 115.146 27.5588V32.0852H112.945V20.3313H113.019Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M136.4 27.1938C136.327 25.6607 136.253 23.7626 136.253 22.1564H136.18C135.813 23.6166 135.3 25.2227 134.786 26.7558L133.026 31.9392H131.339L129.725 26.8288C129.285 25.2957 128.845 23.6896 128.552 22.2294C128.478 23.7626 128.405 25.6607 128.332 27.3398L128.038 32.1582H125.984L126.791 20.2583H129.652L131.192 25.0767C131.632 26.4638 131.999 27.9239 132.292 29.238H132.366C132.659 27.9239 133.099 26.4638 133.539 25.0767L135.226 20.3313H138.087L138.747 32.2312H136.62L136.4 27.1938Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M147.628 26.7558H143.154V30.26H148.215V32.0852H140.953V20.1123H147.921V21.9374H143.154V25.0037H147.628V26.7558Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M154.007 23.8356C154.007 25.4417 153.86 26.9018 153.42 28.2159C153.2 29.019 152.833 29.749 152.54 30.333H157.088V21.9374H154.007V23.8356ZM159.288 20.1123V30.333L160.462 30.4061L160.315 35.0054H158.628L158.481 32.0852H151.22L151.073 35.0054H149.386L149.312 30.4061L150.266 30.333C150.706 29.53 151.146 28.6539 151.44 27.7049C151.806 26.4638 151.953 25.0037 151.953 23.2515V20.1123H159.288Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M164.21 20.1123V24.3466C164.21 26.2448 164.137 27.9969 164.063 29.603C164.65 28.2889 165.457 26.8288 166.264 25.5147L169.418 20.1853H171.618V32.0852H169.565V27.8509C169.565 25.9527 169.565 24.4196 169.711 22.8865C169.124 24.2736 168.318 25.7337 167.584 26.9748L164.577 32.0122H162.156V20.1123H164.21Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M179.975 27.0478L179.021 24.1276C178.801 23.3975 178.581 22.5945 178.434 21.8644C178.288 22.5215 178.068 23.3975 177.848 24.1276L176.894 27.0478H179.975ZM176.527 28.6539L175.5 32.0122H173.227L177.041 20.1123H179.828L183.716 32.0122H181.368L180.268 28.6539H176.527Z" fill="currentColor"/>
        </svg>

    );
};