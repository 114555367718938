import React from 'react';
import '../../styles/UI/Preloader.scss';

function Preloader() {
  return (
    <div class="preloader-wrapper">
        <section className="services section">
            <div className="container container--main-page container--main-page-services">
                <div class="preloader-7">
                    <div>
                        <div>
                            <div>
                                <div>
                                    {/*<div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    </div>
  )
}

export default Preloader;