import axios from "axios";
import { useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { ModalOrderImg } from "../../Svgs/Modals";
import CustomInput from "../../UI/CustomInput";
import "../../../styles/Modals/Modals/ModalOrder.scss";
import "../../../styles/Modals/Modals/ModalThanks.scss";

function ModalEstimation({val, projectName}) {
    const [isFormSended, setIsFormSended] = useState(false);
    let btnSubmit = useRef();

    const onFormSubmit = (evt) => {
        evt.preventDefault();
        btnSubmit.current.setAttribute('disabled', 'disabled')

        const form = evt.target;

        axios({
            method: "post",
            url: "../mail.php",
            data: new FormData(form),
            headers: {
                "Content-type": "application/x-www-form-urlencoded",
            },
        })
            .then(function (response) {
                if (response.data === "ok") {
                    setIsFormSended(true);
                    setTimeout(() => {
                        setIsFormSended(false);
                    }, 300000);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            {isFormSended ? (
                <div className="modal-thanks">
                    <p className="modal-thanks__text">Спасибо! Ваша заявка принята </p>
                </div>
            ) : (
                <form action="/" className="modal-order" onSubmit={onFormSubmit}>
                    <h3 className="modal-order__heading modal-order__heading--small">Спасибо за оценку</h3>
                    <p className="modal-order__text modal-order__text--left">
                        Вы портатили на это время, поэтому мы дарим <b>скидку 5% </b>
                    </p>
                    <ModalOrderImg classes="modal-order__img" />
                    <p className="modal-order__text modal-order__text--left">
                        Отправьте форму и получите скидку на первый заказ
                    </p>
                    <div className="modal-order__inputs">
                        <ReactInputMask
                            required
                            mask="+7 (999) 999-99-99"
                            id=""
                            name="user_phone"
                            type="text"
                            placeholder="Телефон *"
                            className="modal-order__input"
                            pattern="\+7\s?[\(]{0,1}[0-9][0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
                        />
                        <input style={{display: `none`}} name="estimation" value={val}/>
                        <input style={{ display: `none` }} name="form" value={`Форма с оценкой на странице кейса ${projectName}`}/>

                    </div>
                    <button className="btn--64 btn modal-order__btn" type="submit"  ref={btnSubmit}>
                        Зафиксировать скидку
                    </button>
                    <a href="/policy" className="modal-order__policy">
                        Нажимая на кнопку, вы даете согласие на обработку{" "}
                        <span className="modal-order__policy-link">персональных данных</span>
                    </a>
                </form>
            )}
        </>
    );
}

export default ModalEstimation;
