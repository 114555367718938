import React, { Component } from 'react';
import "./styles/App.scss";
import "./styles/Reset.css";
import "./styles/common.scss";
import "./fonts/montserrat/montserrat.css";
import "./fonts/mont/mont.css";
import "./styles/UI/btn.scss";
import "./styles/UI/section-heading.scss";
import "./styles/UI/arrow.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/SectionWrapper.scss";
import Home from "./components/Pages/Home/HomePage";
import ProjectsPage from "./components/Pages/Projects/ProjectsPage";
import Policy from "./components/Pages/Policy/PolicyPage";
import ThanksPage from "./components/Pages/Thanks/ThanksPage";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToAnchor from "./components/ScrollToAnchor";
import { Helmet } from "react-helmet";
import Service from "./components/Pages/Service/Service";
import { Lines } from 'react-preloaders';

function App() {
    return (
        <>
            <Helmet>
                <title>Лидер медиа - разрабатываем сайты, настраиваем рекламу, проводим исследования.</title>
            </Helmet>

            <BrowserRouter>
                <ScrollToTop />
                <ScrollToAnchor />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/policy" element={<Policy />} />
                    <Route exact path="/:name" element={<ProjectsPage />} />
                    {/* <Route exact path="/service/:serviceName" element={<Service />} /> */}
                    <Route exact path="/thanks" element={<ThanksPage />} />
                    
                </Routes>
                
            </BrowserRouter>
        </>
    );
}

export default App;
